// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle from "../css/HLpRTFhim";

const enabledGestures = {KfhxUcSMW: {hover: true}};

const cycleOrder = ["KfhxUcSMW", "HX8rO0e7D"];

const serializationHash = "framer-hsU2i"

const variantClassNames = {HX8rO0e7D: "framer-v-la8boq", KfhxUcSMW: "framer-v-1ik74v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.35, delay: 0, duration: 0.39, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "KfhxUcSMW", Phone: "HX8rO0e7D"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, qvejIqk1P: title ?? props.qvejIqk1P ?? "Home", sWMIKqa4R: link ?? props.sWMIKqa4R, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KfhxUcSMW"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, qvejIqk1P, sWMIKqa4R, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KfhxUcSMW", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const isDisplayed = () => {
if (baseVariant === "HX8rO0e7D") return false
return true
}

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={sWMIKqa4R} motionChild nodeId={"KfhxUcSMW"} scopeId={"RfhQK9Aec"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-1ik74v", className, classNames)} framer-9085i4`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"KfhxUcSMW"} ref={refBinding} style={{...style}} {...addPropertyOverrides({"KfhxUcSMW-hover": {"data-framer-name": undefined}, HX8rO0e7D: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-mdcx06"} data-framer-name={"Dot"} layoutDependency={layoutDependency} layoutId={"YgtFDRkHU"} style={{backgroundColor: "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, opacity: 0}} transformTemplate={transformTemplate1} variants={{"KfhxUcSMW-hover": {opacity: 0.25}}} {...addPropertyOverrides({"KfhxUcSMW-hover": {transformTemplate: undefined}}, baseVariant, gestureVariant)}/>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1oueo73"} data-styles-preset={"HLpRTFhim"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10)))"}}>Home</motion.p></React.Fragment>} className={"framer-1pcds7y"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"VbdXMy7OT"} style={{"--extracted-r6o4lv": "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))", "--framer-paragraph-spacing": "0px"}} text={qvejIqk1P} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hsU2i.framer-9085i4, .framer-hsU2i .framer-9085i4 { display: block; }", ".framer-hsU2i.framer-1ik74v { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-hsU2i .framer-mdcx06 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); left: -12px; overflow: visible; position: absolute; top: 50%; width: 8px; z-index: 1; }", ".framer-hsU2i .framer-1pcds7y { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hsU2i.framer-1ik74v { gap: 0px; } .framer-hsU2i.framer-1ik74v > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-hsU2i.framer-1ik74v > :first-child { margin-left: 0px; } .framer-hsU2i.framer-1ik74v > :last-child { margin-right: 0px; } }", ".framer-hsU2i.framer-v-la8boq.framer-1ik74v { cursor: unset; }", ".framer-hsU2i.framer-v-1ik74v.hover.framer-1ik74v { gap: 7px; }", ".framer-hsU2i.framer-v-1ik74v.hover .framer-mdcx06 { left: unset; position: relative; top: unset; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hsU2i.framer-v-1ik74v.hover.framer-1ik74v { gap: 0px; } .framer-hsU2i.framer-v-1ik74v.hover.framer-1ik74v > * { margin: 0px; margin-left: calc(7px / 2); margin-right: calc(7px / 2); } .framer-hsU2i.framer-v-1ik74v.hover.framer-1ik74v > :first-child { margin-left: 0px; } .framer-hsU2i.framer-v-1ik74v.hover.framer-1ik74v > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 59
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"HX8rO0e7D":{"layout":["auto","auto"]},"sRrZ5CdT7":{"layout":["auto","auto"]}}}
 * @framerVariables {"qvejIqk1P":"title","sWMIKqa4R":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRfhQK9Aec: React.ComponentType<Props> = withCSS(Component, css, "framer-hsU2i") as typeof Component;
export default FramerRfhQK9Aec;

FramerRfhQK9Aec.displayName = "Link";

FramerRfhQK9Aec.defaultProps = {height: 26, width: 59};

addPropertyControls(FramerRfhQK9Aec, {variant: {options: ["KfhxUcSMW", "HX8rO0e7D"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}, qvejIqk1P: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}, sWMIKqa4R: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerRfhQK9Aec, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})